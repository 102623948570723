import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '@context';
import { useApolloClient } from '@apollo/client';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { navigate, PageProps } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import {
  LayoutMissionDesktop,
  LayoutMissionMobile,
  M3MainComponent,
  M3MainComponent2,
} from '@components';
import { useAuth } from '@hooks';
import { Route } from '@interfaces';

const LoansDebtIndex: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const adjustDesktop = {
    paddingLeft: '1rem',
  };
  const adjustMobile = {
    marginTop: '4rem',
  };

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.LoansDebtCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.LoansDebtCategory.m4_2.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  return (
    <>
      <SEO />
      {page === 1 ? (
        <>
          {isMobile ? (
            <LayoutMissionDesktop
              title={
                'Ya tengo un crédito, ¿cómo se si me conviene refinanciarlo?'
              }
              confettiFire={confettiFire}
              number={4}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <M3MainComponent
                setPage={setPage}
                adjustDesktop={adjustDesktop}
              />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionMobile
              title={
                'Ya tengo un crédito, ¿cómo se si me conviene refinanciarlo?'
              }
              confettiFire={confettiFire}
              number={4}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <M3MainComponent setPage={setPage} adjustDesktop={adjustMobile} />
            </LayoutMissionMobile>
          )}
        </>
      ) : (
        <>
          {isMobile ? (
            <LayoutMissionDesktop
              title={
                'Ya tengo un crédito, ¿cómo se si me conviene refinanciarlo?'
              }
              number={4}
              confettiFire={confettiFire}
              secondLayoutForShortcuts
              setPage={setPage}
              pageNumber={1}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <M3MainComponent2
                setPage={setPage}
                adjustDesktop={adjustDesktop}
                handleClick={handleClick}
              />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionMobile
              title={
                'Ya tengo un crédito, ¿cómo se si me conviene refinanciarlo?'
              }
              confettiFire={confettiFire}
              number={4}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <M3MainComponent2
                setPage={setPage}
                adjustDesktop={adjustMobile}
                handleClick={handleClick}
              />
            </LayoutMissionMobile>
          )}
        </>
      )}
    </>
  );
};

export default LoansDebtIndex;
